<h1 class="mt-2">Presentation upload</h1>
<p class="grey">
    If you don't have a presentation
    <span class="skip" (click)="onClickNext()">skip current step</span>.
</p>

<h3>New Presentation</h3>
@if (!file()) {
    <div class="dropzone" dragAndDrop (onDrop)="handleFileDrop($event)">
        <div class="select-video">
            <p>Drag and drop presentation to upload</p>
            <span>PDF only</span>
            <input type="file" #presentationDrop multiple accept="application/pdf" (change)="handleFileSelect($event)"
                   hidden />
            <button class="btn-upload" (click)="presentationDrop.click()">
                Or choose file
                <fa-icon [icon]="faUpload" />
            </button>
        </div>
</div>
<p class="terms-of-service">
    By uploading content to Videolectures.net you agree with our
    <span>Terms of Service</span> policy.
</p>
} @else {
    <app-file [lectureId]="lectureData().lectureId" [name]="file()!.name" [size]="file()!.size" [file]="file()!"
              (onUploaded)="uploadedFile()" (onRemoved)="removeFile()" [link]="createLink(file()!)" />
}

<h3>Uploaded presentations</h3>
<div class="uploaded-files">
    @if (uploadedFiles(); as uploadedFiles) {
        @for (file of uploadedFiles; track file.id) {
            <app-file [lectureId]="lectureData().lectureId" [id]="file.id" [name]="file.filename"
                      [language]="file.language"
                      (onRemoved)="deletePresentation(file)" [link]="file.file" />
        }
    } @else {
        <app-spinner />
    }
</div>

<div class="buttons mt-3">
    <button class="btn mt-2 mb-5 back" (click)="onClickBack()">
        Back
    </button>
    <button class="btn mt-2 mb-5 next" (click)="onClickNext()" [disabled]="file()"
            pTooltip="Upload or remove current presentation!" [tooltipDisabled]="!file()">
        Next
    </button>
</div>
