import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { map, Observable } from 'rxjs';
import { SlugService } from '../../@domain/slug/application/slug.service';

export class UniqueSlugValidator {
    static createValidator(slugService: SlugService): AsyncValidatorFn {
        return (control: AbstractControl): Observable<ValidationErrors> => {
            return slugService.getSlugType(control.value).pipe(
                map(result => result ? { slugExists: true } : {})
            );
        };
    }

}
