import { IMAGE_CONFIG } from '@angular/common';
import { provideHttpClient, withFetch, withInterceptors } from '@angular/common/http';
import { ApplicationConfig, ErrorHandler, importProvidersFrom, inject } from '@angular/core';
import { provideClientHydration, withHttpTransferCacheOptions } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, UrlSerializer, withComponentInputBinding, withInMemoryScrolling } from '@angular/router';
import { ConfirmationPopoverModule } from 'angular-confirmation-popover';
import { provideMarkdown } from 'ngx-markdown';
import { provideMatomo, withRouter } from 'ngx-matomo-client';
import { provideToastr } from 'ngx-toastr';
import { AppConfig } from './@common/configuration/app-config.service';
import { AuthInterceptor } from './@common/http/interceptor/auth.interceptor';
import { FaroErrorHandler } from './@common/log/faro-error-handler.service';
import { Configuration } from './@generated';
import { ROUTES } from './routing/routes';
import { TrailingSlashUrlSerializer } from './routing/util/trailing-slash-url-serializer';

export const appConfig: ApplicationConfig = {
    providers: [
        importProvidersFrom(
            ConfirmationPopoverModule.forRoot({ confirmButtonType: 'danger' })
        ),
        provideHttpClient(withFetch(), withInterceptors([ AuthInterceptor ])),
        provideRouter(
            ROUTES,
            withComponentInputBinding(),
            withInMemoryScrolling({ scrollPositionRestoration: 'enabled' })
        ),
        provideMatomo(
            () => {
                const appConfig = inject(AppConfig);
                return {
                    siteId: 1,
                    disabled: !appConfig.isProduction,
                    trackerUrl: appConfig.matomoUrl,
                };
            },
            withRouter()),
        provideToastr(),
        provideMarkdown(),
        provideAnimations(),
        { provide: UrlSerializer, useClass: TrailingSlashUrlSerializer },
        { provide: ErrorHandler, useClass: FaroErrorHandler },
        {
            provide: Configuration,
            useFactory: (appConfig: AppConfig) => new Configuration({ basePath: appConfig.apiUrl }),
            deps: [ AppConfig ]
        },
        {
            provide: IMAGE_CONFIG,
            useValue: {
                disableImageSizeWarning: true,
            }
        },
        provideClientHydration(withHttpTransferCacheOptions({
            includeRequestsWithAuthHeaders: true
        })),
    ]
};
