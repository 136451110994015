import {AsyncPipe} from '@angular/common';
import {afterNextRender, ChangeDetectionStrategy, Component, inject} from '@angular/core';
import {RouterLink} from '@angular/router';
import {FaIconComponent} from '@fortawesome/angular-fontawesome';
import {ImageDirective} from '../../../@common/DOM/image.directive';
import {HistoryService} from '../../../@domain/history/application/history.service';
import {Modal} from '../../../@domain/modal/modal.enum';
import {ModalService} from '../../../@domain/modal/modal.service';
import {MetaService} from '../../../@domain/seo/meta.service';
import {UserService} from '../../../@domain/user/application/user.service';
import {CategoriesComponent} from '../../../@ui/content/sections/landing-page/categories/categories.component';
import {
    EventsSectionComponent
} from '../../../@ui/content/sections/landing-page/events-section/events-section.component';
import {HistoryComponent} from '../../../@ui/content/sections/landing-page/history/history.component';
import {LatestVideosComponent} from '../../../@ui/content/sections/landing-page/latest-videos/latest-videos.component';
import {NewsComponent} from '../../../@ui/content/sections/landing-page/news/news.component';
import {
    TrendingLecturesComponent
} from '../../../@ui/content/sections/landing-page/trending-lectures/trending-lectures.component';
import {
    UpcomingEventsSectionComponent
} from '../../../@ui/content/sections/landing-page/upcoming-events-section/upcoming-events-section.component';
import {SectionHeaderComponent} from '../../../@ui/global/section-header/section-header.component';
import {WelcomeModalComponent} from '../../../@ui/modals/welcome-modal/welcome-modal.component';
import {AppRoutes} from '../../../routing/types/app-route';

@Component({
    selector: 'app-landing',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [ CategoriesComponent, HistoryComponent, LatestVideosComponent, TrendingLecturesComponent, EventsSectionComponent, SectionHeaderComponent, NewsComponent, UpcomingEventsSectionComponent, FaIconComponent, RouterLink, ImageDirective, AsyncPipe, WelcomeModalComponent ],
    styles: `
        @import "../../../../styles/functions";
        @import "../../../../styles/variables";

        :host {
            width: 100%;
        }

        .banner {
            background-color: $red;
            width: 100%;
            padding: 4.75rem 1rem 4.25rem;

            h1 {
                margin: 0 auto;
                display: block;
                color: $white !important;
                width: fit-content;
                font-size: 3rem;
                font-weight: 800;
                text-align: center;
            }

            @media screen and (max-width: 670px) {
                padding: 2.75rem 1rem 2.25rem;

                h1 {
                    font-size: 2rem;
                }

                app-section-header {
                    margin-bottom: 1rem;
                }
            }
        }

        .last-section {
            display: grid;
            grid-template-columns: 60% 40%;
            gap: 3rem;

            @include break(996px) {
                grid-template-columns: repeat(1, 1fr);
                grid-row-gap: 20px;
                gap: 0;
            }
        }

        .section-placeholder {
            min-height: 540px;
            width: 100%;
        }

        section {
            width: 100%;
            padding: 0.5rem 3rem;

            @include break(996px) {
                padding: 0.5rem 1rem 1rem;
            }

            @include break(670px) {
                padding: 0.5rem 0 1rem;
            }
        }

        .categories-header {
            margin-bottom: 0;
        }
    `,
    template: `
        <div class="banner">
            <h1>Exchange ideas. Share knowledge.</h1>
        </div>

        <div class="sections-container">
            <section>
                <div class="container">
                    <app-section-header class="categories-header" headline="Categories" [link]="AppRoutes.Categories"
                                        buttonText="View all"/>
                    <app-categories ngSkipHydration/>
                </div>
            </section>

            @if (isAuthenticated()) {
                @let usersHistory = usersHistory$ | async;

                @if (usersHistory?.results?.length) {
                    <section class="bg-light">
                        <div class="container">
                            <app-section-header headline="Continue watching" [link]="AppRoutes.History"
                                                buttonText="View all"/>
                            <app-history [history]="usersHistory!.results!"/>
                        </div>
                    </section>
                }
            }
            <section>
                <div class="container">
                    <app-section-header headline="Latest Lectures" [link]="AppRoutes.Videos" buttonText="View all"/>
                    <app-latest-lectures/>
                </div>
            </section>

            <section>
                <div class="container mb-2">
                    <app-section-header headline="Trending" [link]="AppRoutes.Videos" buttonText="View all"
                                        [linkParams]="{ sort : 'starred'}"/>
                    <app-trending-lectures/>
                </div>
            </section>

            <section class="bg-light">
                <div class="container">
                    <app-section-header headline="Latest Events" [link]="AppRoutes.Events" buttonText="View all"/>
                    <app-events-section/>
                </div>
            </section>
        </div>

        <div class="container last-section">
            <div>
                <app-section-header headline="Latest News" [link]="AppRoutes.News" buttonText="All News" isSubHeadline/>
                <app-news/>
            </div>
            <div>
                <app-section-header headline="Upcoming Events" isSubHeadline/>
                <app-upcoming-events-section/>
            </div>
        </div>

        <app-welcome-modal/>
    `
})
export class LandingPage {
    private userService = inject(UserService);
    private modalService = inject(ModalService);
    private metaService = inject(MetaService);
    private historyService = inject(HistoryService);

    public user = this.userService.userProfile;
    public isAuthenticated = this.userService.isAuthenticated;
    public usersHistory$ = this.historyService.getHistory('descending', 4, 1, 'visited');
    public AppRoutes = AppRoutes;
    public Modal = Modal;

    constructor() {
        afterNextRender(() => {
            if (localStorage.getItem('seenModal') !== 'true') {
                this.modalService.open(Modal.WelcomeNotice);
            }
        })

        this.metaService.set({
            title: 'Videolectures - Online Learning Platform',
            description: 'Videolectures is an online learning platform with thousands of free video lectures from the world\'s top lecturers. Join us and start learning today!',
            image: undefined,
            index: true
        })
    }

}
