<h2 class="mt-2">Overview</h2>
<!-- <app-video-player [videoSrc]="videoUrl"></app-video-player> -->
@if (lecture$ | async; as video) {
    <div class="overview-data mt-2">
        <div class="left">
            <h3>Information</h3>
            <div class="row">
                <div class="col-25">Title</div>
                <div class="col-75">{{ video.parts[0].title }}</div>
            </div>
            <div class="row">
                <div class="col-25">Description</div>
                <div class="col-75">{{ video.parts[0].description }}</div>
            </div>
            <div class="row">
                <div class="col-25">Slug</div>
                <div class="col-75">{{ video.slug }}</div>
            </div>
            <div class="row">
                <div class="col-25">Authors</div>
                <div class="col-75">
                    @for (author of video.authors; track author.id; let first = $first) {
                        {{ author.name }}
                        @if (!first) {
                            ,
                        }
                    }
                </div>
            </div>
            <div class="row">
                <div class="col-25">
                    <p>Video type</p>
                </div>
                <div class="col-75">
                    <app-badge [text]="video.type!" />
                </div>
            </div>
            <div class="row">
                <div class="col-25">
                    <p>Language</p>
                </div>
                <div class="col-75">
                    @if (video.language === 'sl') {
                        <p>Slovenian</p>
                    }
                    @if (video.language === 'en') {
                        <p>English</p>
                    }
                    @if (video.language === 'es') {
                        <p>Spanish</p>
                    }
                    @if (video.language === 'fr') {
                        <p>French</p>
                    }
                    @if (video.language === 'de') {
                        <p>German</p>
                    }
                    @if (video.language === 'nl') {
                        <p>Dutch</p>
                    }
                    @if (video.language === 'hr') {
                        <p>Croatian</p>
                    }
                    @if (video.language === 'other') {
                        <p>Other</p>
                    }
                </div>
            </div>
            <div class="row">
                <div class="col-25">Recorded</div>
                <div class="col-75">{{ video.date | date : "dd/MM/yyyy" }}</div>
            </div>
            <div class="row">
                <div class="col-25">Categories</div>
                <div class="col-75">
                    <app-related-categories [overview]="true" [categories]="video.categories" />
                </div>
            </div>
            <div class="row">
                <div class="col-25">Privacy</div>
                <div class="col-75">
                    @if (video.published) {
                        Public - video is visible to everyone
                    } @else {
                        Private - video is only visible to you
                    }
                </div>
            </div>
            <div class="row">
                <div class="col-25">Draft</div>
                <div class="col-75">
                    @if (video.draft) {
                        Draft - video is in draft mode
                    } @else {
                        Published - video is in published mode
                    }
                </div>
            </div>
        </div>
        <div class="right">
            <h3>Uploaded video</h3>
            <img [src]="video.parts[0].thumbnail" [alt]="video.parts[0].title" loading="eager" />
            <div class="video-data">
                <p class="bold">{{ video.parts[0].title }}</p>
            </div>

            @if (video.parts[0].presentations?.length) {
                <h3>Uploaded presentations</h3>
                @for (presentation of video.parts[0].presentations; track presentation) {
                    <div class="presentation-data">
                        <p class="bold">{{ presentation.filename }}</p>
                        @if (presentation.language === 'sl') {
                            <p>Slovenian</p>
                        }
                        @if (presentation.language === 'en') {
                            <p>English</p>
                        }
                        @if (presentation.language === 'es') {
                            <p>Spanish</p>
                        }
                        @if (presentation.language === 'fr') {
                            <p>French</p>
                        }
                        @if (presentation.language === 'de') {
                            <p>German</p>
                        }
                        @if (presentation.language === 'nl') {
                            <p>Dutch</p>
                        }
                        @if (presentation.language === 'hr') {
                            <p>Croatian</p>
                        }
                        @if (presentation.language === 'other') {
                            <p>Other</p>
                        }
                    </div>
                }
            }
        </div>
    </div>
    <div class="buttons mt-3">
        <button class="btn mt-2 mb-5 back" (click)="onClickBack()">Back</button>
        <button class="btn mt-2 mb-5 next" (click)="onClickNext(video)">Finish</button>
    </div>
} @else {
    <div class="flex center">
        <app-spinner class="mt-3 mb-3" />
    </div>
}
