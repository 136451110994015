import { JsonPipe } from '@angular/common';
import { Component, HostListener, inject, signal } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { faImage } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';
import { TooltipModule } from 'primeng/tooltip';
import { tap } from 'rxjs';
import { UniqueSlugValidator } from '../../../@common/form/unique-slug.validator';
import { AuthorService } from '../../../@domain/author/application/author.service';
import { Modal } from '../../../@domain/modal/modal.enum';
import { ModalService } from '../../../@domain/modal/modal.service';
import { SlugService } from '../../../@domain/slug/application/slug.service';
import { UploadUtil } from '../../../@domain/upload/entities/upload-util';
import { ErrorComponent } from '../../global/error/error.component';
import { ModalBodyComponent } from '../../global/modal/modal-body/modal-body.component';
import { ModalFooterComponent } from '../../global/modal/modal-footer/modal-footer.component';
import { ModalHeaderComponent } from '../../global/modal/modal-header/modal-header.component';
import { ModalComponent } from '../../global/modal/modal.component';
import { SpinnerComponent } from '../../global/spinner/spinner.component';

@Component({
    selector: 'app-create-author-modal',
    standalone: true,
    imports: [
        ModalComponent,
        ModalHeaderComponent,
        ModalBodyComponent,
        SpinnerComponent,
        ModalFooterComponent,
        FaIconComponent,
        ReactiveFormsModule,
        ErrorComponent,
        TooltipModule,
        JsonPipe
    ],
    templateUrl: './create-author-modal.component.html',
    styleUrl: './create-author-modal.component.scss'
})
export class CreateAuthorModalComponent {
    private authorService = inject(AuthorService);
    private modalService = inject(ModalService);
    private slugService = inject(SlugService);
    private toastr = inject(ToastrService);
    private fb = inject(FormBuilder);

    authForm = this.fb.group({
        slug: [ '', [ Validators.required ], [ UniqueSlugValidator.createValidator(this.slugService) ] ],
        name: [ '', Validators.required ],
        description: [ '', Validators.required ],
        image: [ '' ]
    });
    file = signal<File | null>(null);
    isLoading = signal(false);
    faImage = faImage;

    @HostListener('drop', [ '$event' ])
    public ondrop(evt: any) {
        evt.preventDefault();
        evt.stopPropagation();
        let files = evt.dataTransfer.files;
        if (files.length > 0) {
            this.selectPhoto(files[0]);
        }
    }

    get slug() {
        return this.authForm.get('slug');
    }

    get name() {
        return this.authForm.get('name');
    }

    get description() {
        return this.authForm.get('description');
    }

    get image() {
        return this.authForm.get('image');
    }

    async selectPhoto(event: any) {
        event.preventDefault();
        event.stopPropagation();
        const file = event.target.files[0];

        if (!UploadUtil.isValidImage(file)) {
            this.toastr.error('Please choose other file type.');
            return;
        }

        if (file.size > 5000000) {
            this.toastr.error('File size is too big. Please choose a smaller file.');
            return;
        }

        this.file.set(file);
        this.image?.setValue(file.name);
    }

    createAuthor() {
        if (this.authForm.invalid) return;

        this.isLoading.set(true);

        this.authorService.postAuthor({
            slug: this.slug!.value!,
            name: this.name!.value!,
            description: this.description!.value!,
            image: this.image!.value!
        }).pipe(
            tap(() => {
                this.toastr.success('Author created successfully!');
                this.file.set(null);
                this.authForm.reset();
            }),
        ).subscribe(() => this.isLoading.set(false));
    }

    onCloseModal(): void {
        this.authForm.reset();
        this.file.set(null);
        this.modalService.close();
    }

    protected readonly Modal = Modal;
}
