import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { AppRoutes } from 'src/app/routing/types/app-route';
import { History } from '../../../../../@domain/history/entities/history.interface';
import { SpinnerComponent } from '../../../../global/spinner/spinner.component';
import { CardGridComponent } from '../../../card-grid/card-grid.component';
import { VideoItemComponent } from '../../video-item/video-item.component';

@Component({
    selector: 'app-history',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [ VideoItemComponent, SpinnerComponent, AsyncPipe, CardGridComponent ],
    styles: `
        @import "../../../../../../styles/variables";

        :host {
            display: block;
            width: 100%;
            min-height: 300px;
        }

        .card h4 {
            color: $black;
        }

        .card-header {
            background-color: $red;
        }

        app-card-grid {
            padding-bottom: 1rem;
        }
    `,
    template: `
            <app-card-grid [listView]="false">
                @for (lecture of history(); track lecture.lecture.id) {
                    <app-video-item [lecture]="lecture.lecture!" priorityImageLoading/>
                }
            </app-card-grid>
    `
})
export class HistoryComponent {
    public history = input.required<History[]>();
    public AppRoutes = AppRoutes;
}
