import { ChangeDetectionStrategy, Component, ElementRef, inject, OnInit, signal, viewChild } from '@angular/core';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { ProgressBarModule } from 'primeng/progressbar';
import { TooltipModule } from 'primeng/tooltip';
import { firstValueFrom } from 'rxjs';
import { DragAndDropDirective } from '../../../../@common/DOM/drag-and-drop.directive';
import { SlidesService } from '../../../../@domain/slides/application/slides.service';
import { UploadService } from '../../../../@domain/upload/application/upload.service';
import { UploadUtil } from '../../../../@domain/upload/entities/upload-util';
import { VideoService } from '../../../../@domain/video/application/video.service';
import { Presentation } from '../../../../@domain/video/entities/presentation.interface';
import { DropdownMenuComponent } from '../../../global/dropdown-menu/dropdown-menu.component';
import { ErrorComponent } from '../../../global/error/error.component';
import { SpinnerComponent } from '../../../global/spinner/spinner.component';
import { FileComponent } from '../file/file.component';

@Component({
    selector: 'app-upload-form3',
    templateUrl: './upload-form3.component.html',
    styleUrls: [ './upload-form3.component.scss' ],
    standalone: true,
    imports: [
        FaIconComponent,
        ErrorComponent,
        DropdownMenuComponent,
        ProgressBarModule,
        TooltipModule,
        FileComponent,
        SpinnerComponent,
        DragAndDropDirective
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UploadForm3Component implements OnInit {
    private uploadService = inject(UploadService);
    private videoService = inject(VideoService);
    private slideService = inject(SlidesService);

    public dropInput = viewChild<ElementRef<HTMLInputElement>>('presentationDrop');
    public file = signal<File | undefined>(undefined);
    public uploadedFiles = signal<Presentation[] | undefined>(undefined);
    public lectureData = this.uploadService.lectureData;
    public videoData = this.uploadService.videoData;
    public faUpload = faUpload;

    public async ngOnInit() {
        await this.loadPresentations()
    }

    public async loadPresentations() {
        if (this.lectureData().slug) {
            const video = await firstValueFrom(this.videoService.getVideo(this.lectureData().slug!));
            this.uploadedFiles.set(video.presentations ?? []);
        } else {
            console.error('No slug found in lecture data');
        }
    }

    public handleFileDrop(file: File | undefined) {
        if (file) {
            this.selectPresentation(file);
        }
    }

    public handleFileSelect(event: Event) {
        const file = (event.target as HTMLInputElement).files?.[0];

        if (file) {
            this.selectPresentation(file)
        }
    }

    private async selectPresentation(file: File) {
        if (!file || !UploadUtil.isPDF(file)) {
            window.alert('You need to upload pdf file.');
            return;
        }

        this.file.set(file);
        this.videoData().presentation = {
            size: UploadUtil.convertToBytes(file.size),
            fileName: file.name,
            base64: await UploadUtil.convertToBase64(file),
        };
    }

    public removeFile() {
        this.file.set(undefined);
        this.videoData().presentation = {};
        // waiting for @if
        setTimeout(() => this.dropInput()!.nativeElement.value = '', 100);
    }

    public uploadedFile() {
        this.removeFile();
        this.uploadedFiles.set(undefined);
        setTimeout(() => this.loadPresentations(), 3000);
    }

    public async deletePresentation(file: Presentation) {
        await this.slideService.deletePresentation(file.id);
        await this.loadPresentations();
    }

    public onClickBack(): void {
        this.uploadService.previousStep();
    }

    public onClickNext(): void {
        this.uploadService.nextStep();
    }

    public createLink(file: File) {
        return URL.createObjectURL(file);
    }

}
