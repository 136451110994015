import { HttpErrorResponse } from '@angular/common/http';

export class ResponseUtil {

    public static is404Response(e: any) {
        return e instanceof HttpErrorResponse && e.status === 404;
    }

    public static is403Response(e: any) {
        return e instanceof HttpErrorResponse && e.status === 403;
    }

    public static is400Response(e: any) {
        return e instanceof HttpErrorResponse && e.status === 400;
    }

    public static is4xxResponse(e: any) {
        return e instanceof HttpErrorResponse && e.status.toString().startsWith('4');
    }
}
