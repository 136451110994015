import { Routes } from '@angular/router';
import AppLayout from '../@ui/layout/app/app.layout';
import { APP_ROUTES } from './app.routes';
import { AppRoutes } from './types/app-route';

export const ROUTES: Routes = [
    // {
    //     path: AdminRoute.Admin,
    //     canActivate: [ authGuardFn, adminGuardFn ],
    //     component: AppLayout,
    //     children: ADMIN_ROUTES
    // },
    {
        path: '',
        component: AppLayout,
        children: APP_ROUTES
    },
    {
        path: `${ AppRoutes.Embed }/${ AppRoutes.Videos }/${ AppRoutes.Slug }`,
        loadComponent: () => import('../@page/app/video/video.page'),
        data: { embed: true }
    },
    {
        path: '**',
        redirectTo: AppRoutes.NotFound
    }
];
