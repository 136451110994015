import { Directive, HostBinding, HostListener, output } from '@angular/core';

@Directive({
    selector: '[dragAndDrop]',
    standalone: true
})
export class DragAndDropDirective {
    @HostBinding('class.drag-over') public isDragOver = false;
    public onDrop = output<File | undefined>();

    @HostListener('dragover', [ '$event' ])
    public onDragOver(event: DragEvent) {
        event.preventDefault();
        event.stopPropagation();
        this.isDragOver = true;
    }

    @HostListener('dragleave', [ '$event' ])
    public onDragLeave(event: DragEvent) {
        event.preventDefault();
        event.stopPropagation();
        this.isDragOver = false;
    }

    @HostListener('drop', [ '$event' ])
    public onDropFile(event: DragEvent) {
        event.preventDefault();
        event.stopPropagation();
        this.isDragOver = false;

        const file = event.dataTransfer?.files[0];
        this.onDrop.emit(file);
    }

}
