import { AsyncPipe, DatePipe } from '@angular/common';
import { Component, inject, input, OnInit, Signal, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { TooltipModule } from 'primeng/tooltip';
import { firstValueFrom, map, Observable } from 'rxjs';
import { EventService } from 'src/app/@domain/event/application/event.service';
import { ModalService } from 'src/app/@domain/modal/modal.service';
import { DropdownOptions } from 'src/app/@ui/global/dropdown-menu/dropdown-options';
import { AuthorService } from '../../../../@domain/author/application/author.service';
import { CategoryService } from '../../../../@domain/category/application/category.service';
import { Modal } from '../../../../@domain/modal/modal.enum';
import { SlugService } from '../../../../@domain/slug/application/slug.service';
import { UploadService } from '../../../../@domain/upload/application/upload.service';
import { LectureData } from '../../../../@domain/upload/entities/lecture-data.interface';
import { VideoUploadService } from '../../../../@domain/video/application/video-upload.service';
import { VideoService } from '../../../../@domain/video/application/video.service';
import { DropdownItem, DropdownMenuComponent } from '../../../global/dropdown-menu/dropdown-menu.component';
import { ErrorComponent } from '../../../global/error/error.component';
import { CreateAuthorModalComponent } from '../../../modals/create-author-modal/create-author-modal.component';
import { LoadLectureModalComponent } from '../../../modals/load-lecture-modal/load-lecture-modal.component';

@Component({
    selector: 'app-upload-form1',
    templateUrl: './upload-form1.component.html',
    styleUrls: [ './upload-form1.component.scss' ],
    standalone: true,
    imports: [
        FormsModule,
        ErrorComponent,
        DropdownMenuComponent,
        AsyncPipe,
        TooltipModule,
        CreateAuthorModalComponent,
        LoadLectureModalComponent,
        DatePipe,
    ],
})
export class UploadForm1Component implements OnInit {
    private modalService = inject(ModalService);
    private eventService = inject(EventService);
    private categoryService = inject(CategoryService);
    private authorsResource = inject(AuthorService);
    private uploadService = inject(UploadService);
    private videoService = inject(VideoService);
    private videoUploadService = inject(VideoUploadService);
    private slugService = inject(SlugService);
    private toaster = inject(ToastrService);

    @ViewChild('typeMenu') typeMenu!: DropdownMenuComponent;
    @ViewChild('langMenu') languageMenu!: DropdownMenuComponent;
    @ViewChild('eventMenu') eventMenu!: DropdownMenuComponent;
    @ViewChild('categoriesMenu') categoriesMenu!: DropdownMenuComponent;
    @ViewChild('authorsMenu') authorsMenu!: DropdownMenuComponent;
    lecture = input<string>();
    eventItems$: Observable<DropdownItem[]> = this.getEvents();
    categoryItems$: Observable<DropdownItem[]> = this.getCategories();
    authorItems$: Observable<DropdownItem[]> = this.getAuthors();
    DropdownOptions = DropdownOptions;
    uploadData: Signal<LectureData> = this.uploadService.lectureData;
    titleError: string = '';
    descriptionError: string = '';
    slugError: string = '';

    async ngOnInit() {
        if (this.lecture()) {
            await this.uploadService.setLectureData(await firstValueFrom(this.videoService.getVideo(this.lecture()!)));
        }
    }

    onChangeTypes([ item ]: DropdownItem[]) {
        this.uploadData().type = item?.id;
    }

    onChangeLanguage([ item ]: DropdownItem[]) {
        this.uploadData().languageId = item?.id;
    }

    onChangeEvent([ item ]: DropdownItem[]) {
        this.uploadData().eventId = +item?.id;
    }

    onChangeCategories(data: DropdownItem[]) {
        this.uploadData().categoryIds = data?.map(el => +el.id);
    }

    onChangeAuthors(data: DropdownItem[]) {
        this.uploadData().authorIds = data?.map(el => +el.id);
    }

    onToggle(value: boolean) {
        this.uploadData().public = value;
    }

    async isValid(): Promise<boolean> {
        let isValid: boolean = true;
        const data = this.uploadData();

        if (!data.title) {
            this.titleError = 'Video title is required!';
            isValid = false;
        }

        if (!data.description?.trim()) {
            this.descriptionError = 'Video description should not be empty!';
            isValid = false;
        }

        if (!data.slug) {
            this.slugError = 'Video slug is required!';
            isValid = false;
        } else {
            const slug = await firstValueFrom(this.slugService.getSlugType(data.slug));

            if (slug && !this.uploadData().lectureId) {
                this.slugError = `Slug already exists as ${ slug } entity!`;
                isValid = false;
            }
        }

        if (!data.type) {
            this.typeMenu.error.set('Video type is required!');
            isValid = false;
        }

        if (data.categoryIds.length === 0) {
            this.categoriesMenu.error.set('At least one category is required!');
            isValid = false;
        }

        if (!data.eventId) {
            this.eventMenu.error.set('Event is required!');
            isValid = false;
        }

        if (!data.languageId) {
            this.languageMenu.error.set('Language is required!');
            isValid = false;
        }

        if (!data.authorIds.length) {
            this.authorsMenu.error.set('At least one author is required!');
            isValid = false;
        }

        return isValid;
    }

    async onClickNext() {
        if (!await this.isValid()) {
            this.toaster.warning('Please fill all required fields!');
            return;
        }

        if (this.uploadData().lectureId) {
            await firstValueFrom(this.videoUploadService.updateVideo(this.uploadData().slug!, this.uploadData()));
        }

        this.uploadService.nextStep();
    }

    getEvents() {
        return this.eventService.getAllEvents('', 99999, 1, 'date', '', true)
            .pipe(
                map(({ results }) => (results ?? []).map(({ title, id }) => ({ text: title, id: id.toString() }))),
            )
    }

    getCategories() {
        return this.categoryService.getAllCategories(99999, 0, 'alphabet', false)
            .pipe(
                map(({ results }) => (results ?? []).map(({ name, id }) => ({ text: name, id: id.toString() }))),
            )
    }

    getAuthors() {
        return this.authorsResource.getAuthors()
            .pipe(
                map(({ results }) => (results ?? []).map(({ name, id }) => ({ text: name, id: id.toString() }))),
            )
    }

    createAuthor() {
        this.modalService.open(Modal.CreateAuthor);
    }

    openImportModal() {
        this.modalService.open(Modal.LoadVideo);
    }

    public async importVideo(slug: string) {
        this.uploadService.importLectureData(await firstValueFrom(this.videoService.getVideo(slug)));
        this.toaster.success('Video imported successfully');
    }
}
