import { HttpEventType } from '@angular/common/http';
import { ChangeDetectorRef, Component, ElementRef, inject, OnInit, signal, viewChild } from '@angular/core';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { faFileImage } from '@fortawesome/free-regular-svg-icons';
import { faCheck, faTimes, faUpload } from '@fortawesome/free-solid-svg-icons';
import { ProgressBarModule } from 'primeng/progressbar';
import { catchError, filter, firstValueFrom, map, switchMap, tap, throwError } from 'rxjs';
import { VideoUploadService } from 'src/app/@domain/video/application/video-upload.service';
import { DragAndDropDirective } from '../../../../@common/DOM/drag-and-drop.directive';
import { ImageDirective } from '../../../../@common/DOM/image.directive';
import { UploadService } from '../../../../@domain/upload/application/upload.service';
import { UploadUtil } from '../../../../@domain/upload/entities/upload-util';
import { VideoService } from '../../../../@domain/video/application/video.service';
import { Video } from '../../../../@domain/video/entities/video.interface';
import { ErrorComponent } from '../../../global/error/error.component';

export enum UploadState {
    NOT_STARTED,
    UPLOADING,
    PROCESSING,
    UPLOADED,
}

@Component({
    selector: 'app-upload-form2',
    templateUrl: './upload-form2.component.html',
    styleUrls: [ './upload-form2.component.scss' ],
    standalone: true,
    imports: [
        FaIconComponent,
        ImageDirective,
        ErrorComponent,
        ProgressBarModule,
        DragAndDropDirective
    ],
})
export class UploadForm2Component implements OnInit {
    private uploadService = inject(UploadService);
    private videoService = inject(VideoService);
    private videoUploadService = inject(VideoUploadService);
    private cdr = inject(ChangeDetectorRef);

    public dropInput = viewChild<ElementRef<HTMLInputElement>>('presentationDrop');
    public videoFile = signal<File | undefined>(undefined);
    public uploadProgress = signal<number>(0);
    public uploadState = signal<UploadState>(UploadState.NOT_STARTED);
    public lectureData = this.uploadService.lectureData;
    public videoData = this.uploadService.videoData;
    public isLoading = signal(false);
    public errorMsg = signal('');
    public UploadState = UploadState;
    public faUpload = faUpload;
    public faTimes = faTimes;
    public faFileImage = faFileImage;
    public faCheck = faCheck;

    public async ngOnInit() {
        if (this.lectureData().lectureId) {
            this.isLoading.set(true);
            const lecture = await firstValueFrom(this.videoService.getVideo(this.lectureData().slug!))
            await this.uploadService.setLectureData(lecture);
            this.videoData().resolution = lecture.videos?.[0].resolution;
            this.videoData().language = lecture.language;

            this.isLoading.set(false);
        }
    }

    public async handleVideoDrop(file: File | undefined) {
        if (!file) {
            return;
        }

        return this.selectVideo(file);
    }

    public async handleVideoSelect(event: Event) {
        const target = event.target as HTMLInputElement;
        const file = target.files?.[0];

        if (file) {
            return this.selectVideo(file);
        }
    }

    private async selectVideo(file: File) {
        this.errorMsg.set('');
        this.videoFile.set(file);
        this.lectureData().videos = [ {
            resolution: 'origin',
            video_format: UploadUtil.getFileExtension(file),
        } ]
        this.videoData().fileName = file.name;
        this.videoData().videoSize = UploadUtil.convertToBytes(file.size);
        this.lectureData().duration = await UploadUtil.getVideoDuration(file);
    }

    public async selectThumbnail(event: any) {
        this.errorMsg.set('');
        const file: File = <File>event.target.files[0];
        const fileExt: string = UploadUtil.getFileExtension(file);

        if (UploadUtil.isValidImageFormat(fileExt)) {
            this.videoData().thumbnail.fileName = file.name;
            this.videoData().thumbnail.extension = fileExt;
            this.videoData().thumbnail.base64 = await UploadUtil.convertToBase64(event.target.files[0]);
            this.lectureData().thumbnail = this.videoData().thumbnail.base64;
            this.cdr.detectChanges();
        } else {
            window.alert('Please choose other file type.');
        }
    }

    public removeVideo() {
        this.videoFile.set(undefined);
        this.videoData().fileName = '';
        this.videoData().videoSize = '';
        this.lectureData().duration = undefined;
        this.lectureData().videos = [];

        if (this.dropInput()) {
            this.dropInput()!.nativeElement.value = '';
        }
    }

    public removeThumbnail() {
        this.videoData().thumbnail = {};
        this.lectureData().thumbnail = '';
    }

    public onClickBack() {
        this.uploadService.previousStep();
    }

    public onClickNext(): void {
        this.uploadService.nextStep();
    }

    public uploadLecture() {
        if (!this.checkForm()) {
            return;
        }

        this.uploadState.set(UploadState.UPLOADING);
        this.videoUploadService.createVideo(this.lectureData()).pipe(
            map(lecture => ({
                url: lecture.videos[0].video_put_url,
                file: this.videoFile(),
                previousRes: lecture,
            })),
            filter(({ url, file }) => url && file),
            switchMap(({ url, file, previousRes }) => this.uploadVideo(url, file!, previousRes)),
            catchError(() => {
                this.uploadState.set(UploadState.NOT_STARTED);
                return throwError(() => 'Upload failed');
            }),
        ).subscribe();
    }

    private uploadVideo(url: string, selectedFile: File, lecture: Video) {
        this.uploadState.set(UploadState.UPLOADING);

        return this.videoUploadService.uploadVideoToMinio(url, selectedFile).pipe(
            tap(res => {
                this.videoData().videoSize = UploadUtil.convertToBytes(selectedFile.size);
                this.videoData().resolution = 'origin';

                if (res.type === HttpEventType.UploadProgress || res.type === HttpEventType.Response) {
                    const progress = res.type === HttpEventType.UploadProgress ? Math.round((res.loaded / res.total) * 100) : 100;
                    this.uploadProgress.set(progress);

                    if (progress === 100) {
                        this.lectureData().lectureId = lecture.id;
                        this.videoData().resolution = lecture.videos?.[0].resolution;
                        this.videoData().language = lecture.language;
                        this.uploadState.set(UploadState.UPLOADED);
                        this.uploadProgress.set(0);
                    }
                }
            }),
            catchError(error => {
                this.videoData().resolution = '';
                this.videoData().language = '';
                window.alert(error);

                return throwError(() => error);
            })
        )
    }

    private checkForm(): boolean {
        if (!this.videoFile()) {
            this.errorMsg.set('Upload video!');
            return false;
        }

        if (!this.videoData().thumbnail.base64) {
            this.errorMsg.set('Upload thumbnail!');
            return false;
        }

        if (this.uploadState() === UploadState.UPLOADING) {
            this.errorMsg.set('Wait for upload to finish!');
            return false;
        }

        this.errorMsg.set('');
        return true;
    }

}
