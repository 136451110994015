import { AsyncPipe, SlicePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { RouterLink } from '@angular/router';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { map } from 'rxjs';
import { EventService } from 'src/app/@domain/event/application/event.service';
import { TransformDateDayPipe } from '../../../../../@common/pipes/transform-date-day.pipe';
import { TransformDateMonthPipe } from '../../../../../@common/pipes/transform-date-month.pipe';
import { Event } from '../../../../../@domain/event/entities/event.interface';
import { AppRoutes } from '../../../../../routing/types/app-route';

@Component({
    selector: 'app-upcoming-events-section',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [ RouterLink, FaIconComponent, AsyncPipe, SlicePipe, TransformDateDayPipe, TransformDateMonthPipe ],
    styles: `
        @import "../../../../../../styles/variables";
        @import "../../../../../../styles/functions";

        h1 {
            font-size: 32px !important;
            color: $black !important;
        }

        .event {
            background-color: $bg-light;
            display: flex;
            padding: 1rem;
            margin-bottom: 1rem;

            .date {
                padding: 1rem 1.5rem;
                text-align: center;
                margin-right: 1rem;
                background-color: $white;

                h3 {
                    font-size: 26px;
                    font-weight: 700;
                    color: $dark;
                }

                p {
                    color: $grey;
                    font-size: 16px;
                }
            }

            .text {
                h5 {
                    padding-bottom: 1rem;
                    font-size: 16px;
                    font-weight: 600;
                    color: $dark;
                }

                p {
                    color: $grey;
                    font-size: 16px;
                }
            }
        }

        .btn-all-events {
            background-color: $red;
            width: 100%;
            padding: 10px 0;

            fa-icon {
                padding-left: 10px;
            }
        }
    `,
    template: `
        <div class="all-events">
            @for (event of upcomingEvents() | slice: 0:3; track event) {
                <div class="event">
                    <div class="date">
                        <h3>{{ event.date | transformDateDay }}</h3>
                        <p>{{ event.date | transformDateMonth }}</p>
                    </div>
                    <div class="text">
                        <h5>{{ event.title }}</h5>
                        <p>{{ event.date }}</p>
                    </div>
                </div>
            }
            @if (upcomingEvents()?.length === 0) {
                <p class="no-content mb-4">There are no upcoming events at the moment.</p>
            }
        </div>
        @if (upcomingEvents()?.length) {
            <a class="btn btn-all-events" style="margin-bottom: 3rem"
                    [routerLink]="AppRoutes.Events" [queryParams]="{ sort: 'date', hits: 'descending' }">
                All events
                <fa-icon [icon]="faArrowRight"/>
            </a>
        }
    `
})
export class UpcomingEventsSectionComponent {
    public upcomingEvents: Signal<Event[] | undefined>;
    public faArrowRight = faArrowRight;
    public AppRoutes = AppRoutes;

    constructor(private eventService: EventService) {
        this.upcomingEvents = toSignal(this.eventService.getUpcomingEvents().pipe(
            map(page => page.results ?? [])
        ));
    }
}
