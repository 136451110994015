import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { RouterLink } from '@angular/router';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { SwiperOptions } from 'swiper';
import { SwiperComponent, SwiperModule } from 'swiper/angular';
import { ImageDirective } from '../../../../../@common/DOM/image.directive';
import { Category } from '../../../../../@domain/category/entities/category.interface';
import { AppRoutes } from '../../../../../routing/types/app-route';
import { SpinnerComponent } from '../../../../global/spinner/spinner.component';

@Component({
    selector: 'app-categories',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        SwiperModule,
        RouterLink,
        ImageDirective,
        FaIconComponent,
        SpinnerComponent,
        AsyncPipe
    ],
    styles: `
        @import "../../../../../../styles/variables";

        :host {
            display: block;
            width: 100%;
            min-height: 175px;
            --slide-size: 150px;
            padding-bottom: 1rem;

            @media screen and (max-width: 900px) {
                --slide-size: 120px;
            }

            @media screen and (max-width: 600px) {
                min-height: 135px;
                --slide-size: 100px;
            }

            .slide {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                text-decoration: none;
                color: $black;
                transition: 0.05s;

                @media screen and (min-width: 600px) {
                    &:hover {
                        font-weight: bold;

                        img {
                            transform: scale(1.1);
                            transition: 0.6s;
                        }
                    }
                }
            }

            img {
                max-height: var(--slide-size);
                height: var(--slide-size);
                transform: scale(1);
                transition: 0.3s;
                aspect-ratio: 1;
            }
        }

        .swiper-button-next,
        .swiper-button-prev {
            position: absolute;
            height: fit-content;
            z-index: 1000;
            cursor: pointer;
            font-size: 2rem;
            color: #6b7280;
            top: 50%;

            &:hover {
                color: $black;
            }
        }

        .swiper-button-prev {
            left: 0;
        }

        .swiper-button-next {
            right: 0;
        }
    `,
    template: `
        <div class="swiper">
            <swiper [config]="config" #swiper>
                @for (category of categories; track category.id; let i = $index) {
                    <ng-template swiperSlide>
                        <a class="slide" [title]="category.name" [routerLink]="[AppRoutes.Categories, category.id]"
                           [queryParams]="{ sort: 'published' }">
                            <img [src]="category.image" alt="{{ category.name }} image"
                                 [loading]="i < 7 ? 'eager' : 'lazy'"/>
                            <p class="text-center">{{ category.name }}</p>
                        </a>
                    </ng-template>
                }
            </swiper>
            <fa-icon class="swiper-button-prev" [icon]="faArrowLeft" (click)="slidePrev()"/>
            <fa-icon class="swiper-button-next" [icon]="faArrowRight" (click)="slideNext()"/>
        </div>
    `
})
export class CategoriesComponent {
    @ViewChild('swiper', { static: false }) swiper?: SwiperComponent;
    AppRoutes = AppRoutes;
    faArrowRight = faArrowRight;
    faArrowLeft = faArrowLeft;
    public categories: Category[] = [
        {
            'id': 13,
            'name': 'Computer Science',
            'image': '/assets/images/categories/computer_science.png',
            'parent': 1,
            'num': 2051,
        },
        {
            'id': 633,
            'name': 'Data Science',
            'image': '/assets/images/categories/data_science.png',
            'parent': 1,
            'num': 1467,
        },
        {
            'id': 2,
            'name': 'Business',
            'image': '/assets/images/categories/business.png',
            'parent': 1,
            'num': 480,
        },
        {
            'id': 60,
            'name': 'Medicine',
            'image': '/assets/images/categories/medicine.png',
            'parent': 1,
            'num': 482,
        },
        {
            'id': 83,
            'name': 'Biology',
            'image': '/assets/images/categories/biology.png',
            'parent': 1,
            'num': 479,
        },
        {
            'id': 58,
            'name': 'Physics',
            'image': '/assets/images/categories/physics.png',
            'parent': 1,
            'num': 433,
        },
        {
            'id': 80,
            'name': 'Architecture',
            'image': '/assets/images/categories/architecture.png',
            'parent': 1,
            'num': 430,
        },
        {
            'id': 23,
            'name': 'Mathematics',
            'image': '/assets/images/categories/mathematics.png',
            'parent': 1,
            'num': 430,
        },
        {
            'id': 59,
            'name': 'Chemistry',
            'image': '/assets/images/categories/chemistry.png',
            'parent': 1,
            'num': 387,
        },
        {
            'id': 171,
            'name': 'Humanities',
            'image': '/assets/images/categories/humanities.png',
            'parent': 1,
            'num': 341,
        },
        {
            'id': 611,
            'name': 'Social Sciences',
            'image': '/assets/images/categories/social_sciences.png',
            'parent': 1,
            'num': 324,
        },
        {
            'id': 424,
            'name': 'Arts',
            'image': '/assets/images/categories/arts.png',
            'parent': 1,
            'num': 298,
        },
        {
            'id': 10,
            'name': 'Science',
            'image': '/assets/images/categories/science.png',
            'parent': 1,
            'num': 266,
        },
        {
            'id': 304,
            'name': 'Earth Sciences',
            'image': '/assets/images/categories/earth_sciences.png',
            'parent': 1,
            'num': 127,
        },
        {
            'id': 353,
            'name': 'Astronomy',
            'image': '/assets/images/categories/astronomy.png',
            'parent': 1,
            'num': 74,
        },
        {
            'id': 3,
            'name': 'Computers',
            'image': '/assets/images/categories/computers.png',
            'parent': 1,
            'num': 72,
        },
        {
            'id': 396,
            'name': 'Life Sciences',
            'image': '/assets/images/categories/life_sciences.png',
            'parent': 1,
            'num': 11,
        }
    ];

    config: SwiperOptions = {
        slidesPerView: 1,
        spaceBetween: 45,
        lazy: true,
        breakpoints: {
            320: {
                slidesPerView: 3,
                spaceBetween: 20,
            },
            450: {
                slidesPerView: 4,
                spaceBetween: 10,
            },
            600: {
                slidesPerView: 5,
                spaceBetween: 20,
            },
            720: {
                slidesPerView: 6,
                spaceBetween: 20,
            },
            900: {
                slidesPerView: 7,
                spaceBetween: 20,
            },
            1120: {
                slidesPerView: 8,
                spaceBetween: 20,
            },
        },
    };

    slideNext() {
        this.swiper?.swiperRef.slideNext(100);
    }

    slidePrev() {
        this.swiper?.swiperRef.slidePrev(100);
    }

}
