import { HttpEventType } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, inject, input, model, OnInit, output, signal } from '@angular/core';
import { RouterLink } from '@angular/router';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { faDownload, faTimes, faUpload } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';
import { catchError, throwError } from 'rxjs';
import { DropdownOptions } from 'src/app/@ui/global/dropdown-menu/dropdown-options';
import { SlidesService } from '../../../../@domain/slides/application/slides.service';
import { UploadUtil } from '../../../../@domain/upload/entities/upload-util';
import { DropdownItem, DropdownMenuComponent } from '../../../global/dropdown-menu/dropdown-menu.component';
import { ErrorComponent } from '../../../global/error/error.component';
import { SpinnerComponent } from '../../../global/spinner/spinner.component';
import { UploadState } from '../upload-form2/upload-form2.component';

@Component({
    selector: 'app-file',
    standalone: true,
    imports: [
        DropdownMenuComponent,
        ErrorComponent,
        FaIconComponent,
        RouterLink,
        SpinnerComponent
    ],
    templateUrl: './file.component.html',
    styleUrl: './file.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileComponent implements OnInit {
    private slideService = inject(SlidesService);
    private toastr = inject(ToastrService);

    public id = input<number>();
    public lectureId = input<number>();
    public name = input.required<string>();
    public link = input.required<string>();
    public file = input<File | undefined>();
    public size = input<number>();
    public language = model<string>();
    public uploadProgress = signal<number>(0);
    public uploadState = signal<UploadState>(UploadState.NOT_STARTED);
    public onUploaded = output();
    public onRemoved = output();
    public Languages = DropdownOptions.language;
    public UploadState = UploadState;
    public faTimes = faTimes;
    public faUpload = faUpload;
    public faDownload = faDownload;

    public ngOnInit() {
        if (!this.file() && !this.id()) {
            console.error('File id is required, for existing files');
        }
    }

    public selectLanguage([ data ]: DropdownItem[]): void {
        this.language.set(data.id);
    }

    public async upload() {
        if (!this.isValid()) {
            return;
        }

        this.uploadState.set(UploadState.UPLOADING);

        this.slideService.uploadSlidesFile({
            file: await UploadUtil.convertToBase64(this.file()!),
            lecture: this.lectureId()!,
            language: this.language()!,
            filename: this.name(),
            size: this.size()!,
        }).pipe(
            catchError(error => {
                this.uploadState.set(UploadState.NOT_STARTED);
                return throwError(() => error);
            })
        ).subscribe((res) => {
            if (res.type == HttpEventType.UploadProgress) {
                const progress = Math.round((res.loaded / res.total!) * 100);
                this.uploadProgress.set(Math.round(progress));

                if (progress == 100) {
                    this.uploadState.set(UploadState.PROCESSING);
                }
            }

            if (res.type == HttpEventType.Response && res.status < 300) {
                this.toastr.success('Slides uploaded successfully!', undefined, { timeOut: 3000 });
                this.uploadState.set(UploadState.UPLOADED);
                this.uploadProgress.set(0);
                this.onUploaded.emit();
            }
        });
    }

    private isValid(): boolean {
        if (!this.language()) {
            this.toastr.error('Please select presentation language!', undefined, { timeOut: 3000 });
            return false;
        }

        return true;
    }

    public async remove() {
        this.onRemoved.emit();
    }

    public download() {
        const link = document.createElement('a');
        link.href = this.link();
        link.download = this.name();
        link.click();
        link.remove();
    }

}
