import { DropdownItem } from './dropdown-menu.component';

export const DropdownOptions: { language: DropdownItem[], types: DropdownItem[] } = {
    language: [
        { text: 'Slovenian', id: 'sl' },
        { text: 'English', id: 'en' },
        { text: 'Spanish', id: 'es' },
        { text: 'Russian', id: 'ru' },
        { text: 'French', id: 'fr' },
        { text: 'German', id: 'de' },
        { text: 'Dutch', id: 'nl' },
        { text: 'Croatian', id: 'hr' },
        { text: 'Other', id: 'other' },
    ],
    types: [
        { text: 'Lecture', id: 'lecture' },
        { text: 'Tutorial', id: 'tutorial' },
        { text: 'Keynote', id: 'keynote' },
        { text: 'Demonstration', id: 'demonstration' },
        { text: 'Opening', id: 'opening' },
        { text: 'Interview', id: 'interview' },
        { text: 'Debate', id: 'debate' },
        { text: 'Panel', id: 'panel' },
        { text: 'Summary', id: 'summary' },
    ]
}
