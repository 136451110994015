import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, Injector, numberAttribute, OnInit, Signal } from '@angular/core';
import { injectQueryParams } from 'ngxtension/inject-query-params';
import { BookmarkService } from 'src/app/@domain/bookmark/application/bookmark.service';
import { Page } from '../../../../../@common/entities/page.interface';
import { computedAsync } from '../../../../../@common/rxjs/computed-async';
import { Video } from '../../../../../@domain/video/entities/video.interface';
import { PaginationComponent } from '../../../../global/pagination/pagination.component';
import { SpinnerComponent } from '../../../../global/spinner/spinner.component';
import { CardGridComponent } from '../../../card-grid/card-grid.component';
import { VideoItemComponent } from '../../video-item/video-item.component';

@Component({
    selector: 'app-user-bookmarks',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [ VideoItemComponent, PaginationComponent, SpinnerComponent, AsyncPipe, CardGridComponent ],
    styles: `
        :host {
            position: relative;
            flex: 1;
            display: flex;
            flex-direction: column;
        }
    `,
    template: `
        @if (bookmarkedLecturesPage(); as bookmarkPage) {
            <app-card-grid listView>
                @for (lecture of bookmarkPage.results; track lecture; let even = $even) {
                    <app-video-item [lecture]="lecture" [isBookmark]="true" listView/>
                }
                <app-pagination [totalItems]="bookmarkPage.count" [pageSize]="pageSize"/>
            </app-card-grid>

            @if (!bookmarkPage.results?.length) {
                <p class="no-content">You do not have any saved bookmarks.</p>
            }
        } @else {
            <app-spinner/>
        }
    `
})
export class UserBookmarksComponent implements OnInit {
    private bookmarksService = inject(BookmarkService);
    private injector = inject(Injector);

    public page = injectQueryParams('page', { transform: numberAttribute });
    public bookmarkedLecturesPage!: Signal<Page<Video> | undefined>;
    public pageSize = 8;

    public ngOnInit() {
        this.bookmarkedLecturesPage = computedAsync(
            () => this.bookmarksService.getBookmarkedLectures(this.pageSize, this.page() ?? 1),
            { injector: this.injector }
        )
    }
}
